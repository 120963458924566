<template>
  <div class="content">
    <div class="row">
      <div class="col">
        <div class="background p-3">
          <div class="row align-items-center">
            <div class="col-12">
              <input type="text" class="form-control" placeholder="Question" v-model="question" :disabled="lock" />
            </div>
            <div class="col-12 mt-2">
              <editor :api-key="tinyMCEApiKey" :init="tinyMCEConfig" v-model="answer" :value="answer" v-if="!lock" />
              <div v-else class="d-flex justify-content-center align-items-center" style="height: 100%;width:100%;">
                <div>
                  Loading editor
                  <div class="spinner-border spinner-border-sm ms-2" role="status">
                    <span class="visually-hidden">Loading...</span>
                  </div>
                </div>
              </div>
            </div>
            <div class="col-12 mt-2">
              <button class="vave-btn btn-green" @click="handleFaq()" v-html="mode == 'edit' ? 'Update' : 'Create'"></button>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="row" v-if="mode == 'edit'">
      <div class="col">
        <div class="background mt-4 p-3">
          <div class="row">
            <div class="col">
              <h6>Products</h6>
            </div>
          </div>
          <div class="row">
            <div class="col">
              <div class="row align-items-center">
                <div class="col-6">
                  <div class="my-2">
                    <products-input v-if="productsReady" v-model="products" type="name" key="products" @ready="productsInputReady = true" />
                  </div>
                </div>
                <div class="col-2">
                  <button class="vave-btn btn-green" @click="pushProducts()" :disabled="!productsInputReady">Save Products</button>
                </div>
              </div>
            </div>
          </div>

          <div class="row">
            <div class="col">
              <div class="entries-table">
                <table class="table">
                  <thead>
                    <th>id</th>
                    <th>name</th>
                    <th>sku</th>
                  </thead>
                  <tbody>
                    <tr v-for="product,k in products" :key="k" :class="{'synched': !productsSynched.includes(product.id)}">
                      <td>{{product.id}}</td>
                      <td>{{product.name}}</td>
                      <td>{{product.sku}}</td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="row" v-if="mode == 'edit'">
      <div class="col">
        <div class="background mt-4 p-3">
          <div class="row">
            <div class="col">
              <h6>Categories</h6>
            </div>
          </div>
          <div class="row">
            <div class="col">
              <div class="row align-items-center">
                <div class="col-6">
                  <div class="my-2">
                    <categories-input v-if="categoriesReady" v-model="categories" type="name" key="categories" @ready="categoriesInputReady = true" />
                  </div>
                </div>
                <div class="col-2">
                  <button class="vave-btn btn-green" @click="pushCategories()" :disabled="!categoriesInputReady">Save Categories</button>
                </div>
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col">
              <div class="entries-table">
                <table class="table">
                  <thead>
                    <th>id</th>
                    <th>name</th>
                    <th>sku</th>
                  </thead>
                  <tbody>
                    <tr v-for="category,k in categories" :key="k" :class="{'synched': !categoriesSynched.includes(category.id)}">
                      <td>{{category.id}}</td>
                      <td>{{category.name}}</td>
                      <td>{{category.sku}}</td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "../http.js";
import ProductsInput from "./widgets/helpers/Products";
import CategoriesInput from "./widgets/helpers/CategoriesMultiselect";
import Editor from "@tinymce/tinymce-vue";

export default {
  data() {
    return {
      lock: false,
      mode: "create",
      categoriesReady: false,
      productsReady: false,
      question: "",
      answer: "",
      categoriesInputReady: false,
      productsInputReady: false,
      categories: [],
      products: [],
      categoriesSynched: [],
      productsSynched: [],
      tinyMCEApiKey: process.env.VUE_APP_TINYMCE_API_KEY,
      tinyMCEConfig: {
        height: "300px",
        menubar: false,
        plugins: [
          "advlist autolink lists link image charmap print preview anchor",
          "searchreplace visualblocks code fullscreen",
          "insertdatetime media table paste code",
        ],
        toolbar:
          "undo redo | formatselect | bold italic | \
           alignleft aligncenter alignright alignjustify | \
           bullist numlist outdent indent image | removeformat | preview |",
        automatic_uploads: true,
        image_class_list: [{ question: "limit-width", value: "limit-width" }], // Note: if we add new classes, this one won't be added by default to all the images
        images_upload_url: process.env.VUE_APP_VAVEURL + "/api/admin/assets",
        images_upload_credentials: true,
        images_upload_handler: function (blobInfo, success, failure) {
          var formData = new FormData();
          formData.append("asset", blobInfo.blob());
          formData.append("type", "image");
          axios
            .post("/api/admin/assets", formData, {
              headers: {
                "Content-Type": "multipart/form-data",
              },
            })
            .then(
              (response) => {
                success(response.data.url);
              },
              () => {
                failure("There was a network error.");
              }
            )
            .catch(() => {
              failure("There was an exception.");
            });
        },
        setup: function (editor) {
          var getDialogConfig = function (products, query) {
            let itemsList = [
              {
                type: "label",
                label: "Search for products",
                items: [
                  {
                    type: "grid",
                    columns: 2,
                    items: [
                      {
                        type: "input",
                        name: "search-input",
                        inputMode: "text",
                      },
                      {
                        type: "button",
                        name: "search-button",
                        text: "Search",
                      },
                    ],
                  },
                ],
              },
              {
                type: "label",
                label: "or",
                items: [
                  {
                    type: "button",
                    name: "load-all-products",
                    text: "Load All Products",
                  },
                ],
              },
            ];
            let label = "Products";
            if (query) {
              label = label + " matching the query: " + query;
            }
            if (products) {
              itemsList.push({
                type: "selectbox",
                name: "productsSelect",
                label: label,
                items: products.map((product) => {
                  return {
                    text: product.name,
                    value: String(product.remote_id),
                  };
                }),
                flex: true,
              });
            }
            return {
              question: "Insert Product",
              body: {
                type: "panel",
                items: itemsList,
              },
              onAction: function (api, details) {
                if (details.name == "load-all-products") {
                  api.block("Loading all products...");
                  axios.get("api/products?per_page=9999").then((r) => {
                    if (r.status != 200) {
                      console.error("problem fetching products");
                    }
                    api.redial(getDialogConfig(r.data.data));
                    api.unblock();
                  });
                }
                if (details.name == "search-button") {
                  let query = api.getData()["search-input"];
                  api.block("Loading...");
                  axios
                    .get("/api/products/search?q=" + query)
                    .then((response) => {
                      let products = response.data.data;
                      api.redial(getDialogConfig(products, query));
                      api.unblock();
                    });
                }
              },
              onSubmit: function (api) {
                editor.insertContent(
                  "[showproduct id=" + api.getData()["productsSelect"] + "]"
                );
                api.close();
              },
              buttons: [
                {
                  text: "Close",
                  type: "cancel",
                  onclick: "close",
                },
                {
                  text: "Insert",
                  type: "submit",
                  primary: true,
                  enabled: false,
                },
              ],
            };
          };

          editor.ui.registry.addButton("productButton", {
            icon: "format-painter",
            text: "Add Product",
            tooltip: "Insert product shortcode",
            disabled: false,
            onAction: function () {
              var _dialog = editor.windowManager.open(getDialogConfig());
              _dialog.redial(getDialogConfig());
            },
            onSetup: function (buttonApi) {
              var editorEventCallback = function (eventApi) {
                buttonApi.setDisabled(
                  eventApi.element.nodeName.toLowerCase() === "time"
                );
              };
              editor.on("NodeChange", editorEventCallback);

              /* onSetup should always return the unbind handlers */
              return function () {
                editor.off("NodeChange", editorEventCallback);
              };
            },
          });
        },
      },
    };
  },
  watch: {
    $route() {
      this.init();
    },
  },
  components: { ProductsInput, CategoriesInput, Editor },
  mounted() {
    this.init();
  },
  methods: {
    init() {
      this.mode = this.$route.params.id ? "edit" : "create";

      if (this.mode === "edit") {
        this.lock = true;
        this.getFaq();
      }
    },
    handleFaq() {
      if (this.mode == "create") {
        this.createFaq();
      } else {
        this.updateFaq();
      }
    },
    getFaq() {
      axios.get("/api/admin/faqs/" + this.$route.params.id).then((response) => {
        if (response.data.data) {
          this.lock = false;
          let res = response.data.data;
          this.answer = res.answer;
          this.question = res.question;
        }
      });

      axios
        .get("/api/admin/faqs/" + this.$route.params.id + "/categories")
        .then((response) => {
          if (response.data.data) {
            let categories = response.data.data;
            var p = [];
            var p2 = [];
            categories.forEach((category) => {
              p.push({
                id: category.id,
                name: category.name,
              });
              p2.push(category.id);
            });
            this.categories = p;
            this.categoriesSynched = p2;
            this.categoriesReady = true;
          }
        });

      axios
        .get("/api/admin/faqs/" + this.$route.params.id + "/products")
        .then((response) => {
          if (response.data.data) {
            let products = response.data.data;
            var p = [];
            var p2 = [];
            products.forEach((product) => {
              p.push({
                id: product.id,
                name: product.name,
                question: product.question,
                sku: product.sku,
              });
              p2.push(product.id);
            });
            this.products = p;
            this.productsSynched = p2;
            this.productsReady = true;
          }
        });
    },
    createFaq() {
      if (
        !confirm('do you want to create a new FAQ: "' + this.question + '"?')
      ) {
        return;
      }
      axios
        .post("/api/admin/faqs", {
          question: this.question,
          answer: this.answer,
        })
        .then((response) => {
          if (response.status == 201) {
            this.$toast.success("FAQ created");
            this.$router.push("/faqs/" + response.data.data.id + "/edit");
            return;
          }
        });
    },
    updateFaq() {
      if (
        !confirm(
          'do you want to update the FAQ with id "' +
            this.$route.params.id +
            '"?'
        )
      ) {
        return;
      }
      axios
        .put("/api/admin/faqs/" + this.$route.params.id, {
          name: this.name,
          question: this.question,
          answer: this.answer,
          published: this.published,
        })
        .then(
          () => {
            this.$toast.success("FAQupdated");
            this.getFaq();
          },
          (e) => {
            alert(e.response.data.message);
          }
        );
    },
    pushProducts() {
      axios
        .put("/api/admin/faqs/" + this.$route.params.id + "/products", {
          products: this.products,
        })
        .then(
          () => {
            this.$toast.success("Products synchronised");
            this.getFaq();
          },
          (e) => {
            this.$toast.error(e.response.data.message);
          }
        );
    },
    pushCategories() {
      axios
        .put("/api/admin/faqs/" + this.$route.params.id + "/categories", {
          categories: this.categories,
        })
        .then(
          () => {
            this.$toast.success("Categories synchronised");
            this.getFaq();
          },
          (e) => {
            this.$toast.error(e.response.data.message);
          }
        );
    },
  },
};
</script>

<style lang="scss" scoped>
.background {
  background: white;
}
.background-tmp {
  background: hsl(42, 100%, 71%);
}
.background-deleted {
  background: hsl(0, 95%, 65%);
}
.entries-table {
  tbody tr {
    &:hover {
      background: #fafafa;
    }
  }
}
.create {
  margin: 20px 0px;
  padding-bottom: 20px;
}
.synched {
  background: hsl(42, 100%, 71%);
}
.editable-input {
  border: none;
  border-bottom: 1px dotted black;
  width: 60px;
}
.invalid-number-input {
  color: red;
  border-bottom: 1px dotted red;
}
.invalid-row {
  color: red;
}
.fixed-width-td {
  width: 300px;
}
</style>